import React from 'react'
import Header from './header'
import Footer from './footer'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, pages, contactInfoRaw, homePage}) => (
  <>
    <Header siteTitle={siteTitle} pages={pages} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} homePage={homePage} />
    <div className={styles.content}>{children}</div>
    <Footer siteTitle={siteTitle} pages={pages} contactInfoRaw={contactInfoRaw} homePage={homePage} />
  </>
)

export default Layout
