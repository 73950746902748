import { Link } from "gatsby";
import React from "react";
import Menu from "./menu";
import Logo from "./logo";
import PortableText from "./portableText";

import styles from "./footer.module.css";

const Footer = ({ onHideNav, onShowNav, showNav, siteTitle, pages, contactInfoRaw }) => (
  <footer className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.contentLeft}>
        <Menu pages={pages} footer />
      </div>
      <div className={styles.branding}>
        <Link to="/">
          <Logo vertical className={styles.logo} />
        </Link>
      </div>
      <div className={styles.contentRight}>
        {contactInfoRaw && <PortableText blocks={contactInfoRaw} />}
      </div>
    </div>
    <div className={styles.partnersWrapper}>
      <h3 className={styles.partnersHeading}>Samarbeidspartnere:</h3>
      <ul className={styles.logos}>
        <li className={styles.logo}>
          <a href="https://www.tysse.no">
            <img
              src="https://cdn.sanity.io/images/m48st3nc/production/8ed3514f08367b6caa3ea8a0944e9daeeaaaed0c-600x128.png"
              width="80%"
            />
          </a>
        </li>
        <li className={styles.logo}>
          <a href="https://www.volkswagen-nyttekjoretoy.no/">
            <img
              src="https://cdn.sanity.io/images/m48st3nc/production/47c95d2c216bcc79abfe6db6b14edac9d6b2f82c-600x212.png"
              width="100%"
            />
          </a>
        </li>
        <li className={styles.logo}>
          <a href="https://www.cranepartner.no/">
            <img
              src="https://cdn.sanity.io/images/m48st3nc/production/e468f459d4ad1da57269a208a85fbad0ba99ce6a-423x40.png"
              width="100%"
            />
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
