import {Link} from 'gatsby'
import React from 'react'
import {getPageUrl} from '../lib/helpers'
// import Icon from './icon'

import styles from './menu.module.css'

const hideHomePage = (homePage, slug) =>
  homePage && slug && slug.current === 'index'

const Menu = ({pages, header, footer, homePage}) => (
  <nav className={footer ? styles.menuFooter : styles.menu}>
    <div className={footer ? null : homePage ? styles.menuWrapperHome : styles.menuWrapper}>
      {pages && pages.map(page => hideHomePage(homePage, page.slug) ? null : (
        <Link
          to={getPageUrl(page.slug)}
          className={styles.menuItem}
        >{page.name || page.title}</Link>
      ))}
      {/* <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button> */}
    </div>
  </nav>
)

export default Menu
