import React from 'react'

import styles from './logo.module.css'

const Logo = ({vertical}) => vertical ? (
  <svg viewBox='0 0 106 111' version='1.1' className={styles.logo}>
    <g id='Logo' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Symbol' transform='translate(25.000000, 1.000000)'>
        <path d='M28.64706,0.436456146 L28.64706,0.436456146 L41.12206,9.50045615 L53.74206,18.6924561 C54.37606,19.1544561 54.71206,20.1504561 54.44006,21.1614561 L49.67606,35.8274561 L44.83306,50.6704561 C44.59006,51.4154561 43.74606,52.0424561 42.70106,52.0974561 L27.28106,52.0974561 L11.66706,52.0784561 C10.88406,52.0784561 10.02606,51.4694561 9.65206004,50.4924561 L9.65206004,50.4914561 L4.88606004,35.8274561 L0.0790600399,20.9714561 C-0.16193996,20.2254561 0.15206004,19.2224561 0.96606004,18.5634561 L13.44106,9.50045615 L26.08306,0.337456146 C26.71706,-0.122543854 27.76906,-0.133543854 28.64706,0.436456146' id='Fill-9' fill='#FFFFFE' />
        <path d='M36.63536,20.0160561 L36.63536,24.1620561 C36.63536,24.4510561 36.39936,24.6870561 36.11036,24.6870561 L18.42436,24.6870561 C18.13636,24.6870561 17.90036,24.4510561 17.90036,24.1620561 L17.90036,20.0160561 C17.90036,19.7280561 18.13636,19.4910561 18.42436,19.4910561 L36.11036,19.4910561 C36.39936,19.4910561 36.63536,19.7280561 36.63536,20.0160561 M17.23536,26.0460561 L37.29936,26.0460561 C37.62636,26.0460561 37.89436,26.3140561 37.89436,26.6420561 L37.89436,31.3450561 C37.89436,31.6720561 37.62636,31.9400561 37.29936,31.9400561 L17.23536,31.9400561 C16.90836,31.9400561 16.64036,31.6720561 16.64036,31.3450561 L16.64036,26.6420561 C16.64036,26.3140561 16.90836,26.0460561 17.23536,26.0460561 M15.77436,33.3000561 L38.76136,33.3000561 C39.13636,33.3000561 39.44336,33.6070561 39.44336,33.9820561 L39.44336,39.3710561 C39.44336,39.7450561 39.13636,40.0520561 38.76136,40.0520561 L15.77436,40.0520561 C15.39936,40.0520561 15.09136,39.7450561 15.09136,39.3710561 L15.09136,33.9820561 C15.09136,33.6070561 15.39936,33.3000561 15.77436,33.3000561' id='Fill-11' fill='#465676' />
        <polygon id='Fill-13' fill='#465676' points='46.30396 46.1634561 38.96596 16.1534561 27.88596 16.1534561 26.64896 16.1534561 15.56796 16.1534561 8.23696004 46.1384561 9.33596004 49.5214561 17.03596 18.0244561 26.64896 18.0244561 27.88596 18.0244561 37.49796 18.0244561 45.20196 49.5384561' />
      </g>
      <g id='Text' transform='translate(0.000000, 69.000000)' fill='#FFFFFE'>
        <polygon id='Fill-15' points='5.0912 33.3806 6.3452 33.3806 6.3452 40.2206 8.4082 40.2206 8.4082 41.3606 5.0912 41.3606' />
        <path d='M16.9332,38.7161 L16.3742,34.7711 L16.3522,34.7711 L15.8042,38.7161 L16.9332,38.7161 Z M15.5652,33.3811 L17.2642,33.3811 L18.5632,41.3611 L17.3092,41.3611 L17.0812,39.7761 L17.0812,39.7991 L15.6562,39.7991 L15.4282,41.3611 L14.2652,41.3611 L15.5652,33.3811 Z' id='Fill-17' />
        <path d='M24.6586,39.4685 L24.6586,39.0125 L25.8446,39.0125 L25.8446,39.5595 C25.8446,40.1295 26.0956,40.3345 26.4936,40.3345 C26.8936,40.3345 27.1446,40.1295 27.1446,39.5375 C27.1446,38.8755 26.8936,38.4535 26.0726,37.7355 C25.0236,36.8125 24.6816,36.1625 24.6816,35.2505 C24.6816,33.9965 25.3316,33.2675 26.5506,33.2675 C27.7716,33.2675 28.3746,33.9965 28.3746,35.2735 L28.3746,35.6045 L27.1896,35.6045 L27.1896,35.1935 C27.1896,34.6235 26.9616,34.4065 26.5626,34.4065 C26.1636,34.4065 25.9356,34.6235 25.9356,35.1705 C25.9356,35.7525 26.1976,36.1735 27.0176,36.8915 C28.0676,37.8155 28.3986,38.4535 28.3986,39.4455 C28.3986,40.7455 27.7366,41.4745 26.5056,41.4745 C25.2746,41.4745 24.6586,40.7455 24.6586,39.4685' id='Fill-19' />
        <polygon id='Fill-21' points='35.7586 34.5207 34.4476 34.5207 34.4476 33.3807 38.3236 33.3807 38.3236 34.5207 37.0126 34.5207 37.0126 41.3607 35.7586 41.3607' />
        <path d='M44.2594,39.4685 L44.2594,39.0125 L45.4454,39.0125 L45.4454,39.5595 C45.4454,40.1295 45.6964,40.3345 46.0944,40.3345 C46.4944,40.3345 46.7454,40.1295 46.7454,39.5375 C46.7454,38.8755 46.4944,38.4535 45.6734,37.7355 C44.6244,36.8125 44.2814,36.1625 44.2814,35.2505 C44.2814,33.9965 44.9324,33.2675 46.1514,33.2675 C47.3724,33.2675 47.9754,33.9965 47.9754,35.2735 L47.9754,35.6045 L46.7904,35.6045 L46.7904,35.1935 C46.7904,34.6235 46.5624,34.4065 46.1634,34.4065 C45.7644,34.4065 45.5364,34.6235 45.5364,35.1705 C45.5364,35.7525 45.7984,36.1735 46.6184,36.8915 C47.6684,37.8155 47.9994,38.4535 47.9994,39.4455 C47.9994,40.7455 47.3374,41.4745 46.1064,41.4745 C44.8754,41.4745 44.2594,40.7455 44.2594,39.4685' id='Fill-23' />
        <polygon id='Fill-25' points='54.379 41.361 55.633 41.361 55.633 33.381 54.379 33.381' />
        <polygon id='Fill-27' points='62.1851 33.3806 63.4391 33.3806 63.4391 36.7436 65.0351 33.3806 66.2901 33.3806 64.7961 36.3106 66.3121 41.3606 65.0011 41.3606 63.9411 37.8036 63.4391 38.8186 63.4391 41.3606 62.1851 41.3606' />
        <path d='M74.2539,36.8008 C74.7219,36.8008 75.0069,36.5958 75.0069,35.9568 L75.0069,35.3418 C75.0069,34.7708 74.8129,34.5208 74.3679,34.5208 L73.7989,34.5208 L73.7989,36.8008 L74.2539,36.8008 Z M72.5439,33.3808 L74.4019,33.3808 C75.7019,33.3808 76.2599,33.9848 76.2599,35.2158 L76.2599,35.7068 C76.2599,36.5268 75.9979,37.0518 75.4399,37.2798 L75.4399,37.3028 C76.0669,37.4968 76.2719,38.0888 76.2719,38.9898 L76.2719,40.3918 C76.2719,40.7788 76.2829,41.0648 76.4089,41.3608 L75.1319,41.3608 C75.0639,41.1558 75.0179,41.0298 75.0179,40.3808 L75.0179,38.9218 C75.0179,38.1798 74.8009,37.9408 74.2319,37.9408 L73.7989,37.9408 L73.7989,41.3608 L72.5439,41.3608 L72.5439,33.3808 Z' id='Fill-28' />
        <polygon id='Fill-29' points='82.766 41.361 84.02 41.361 84.02 33.381 82.766 33.381' />
        <polygon id='Fill-30' points='90.5722 33.3806 92.1452 33.3806 93.3652 38.1576 93.3882 38.1576 93.3882 33.3806 94.5052 33.3806 94.5052 41.3606 93.2172 41.3606 91.7122 35.5356 91.6892 35.5356 91.6892 41.3606 90.5722 41.3606' />
        <path d='M100.9884,39.4685 L100.9884,35.2735 C100.9884,33.9965 101.6264,33.2675 102.8574,33.2675 C104.0894,33.2675 104.7274,33.9965 104.7274,35.2735 L104.7274,35.9575 L103.5424,35.9575 L103.5424,35.1935 C103.5424,34.6235 103.2914,34.4065 102.8914,34.4065 C102.4924,34.4065 102.2414,34.6235 102.2414,35.1935 L102.2414,39.5595 C102.2414,40.1295 102.4924,40.3345 102.8914,40.3345 C103.2914,40.3345 103.5424,40.1295 103.5424,39.5595 L103.5424,37.9975 L102.9154,37.9975 L102.9154,36.8575 L104.7274,36.8575 L104.7274,39.4685 C104.7274,40.7455 104.0894,41.4745 102.8574,41.4745 C101.6264,41.4745 100.9884,40.7455 100.9884,39.4685' id='Fill-31' />
        <polygon id='Fill-32' points='5.059 5.0077 0.496 5.0077 0.496 1.0397 13.987 1.0397 13.987 5.0077 9.424 5.0077 9.424 28.8147 5.059 28.8147' />
        <path d='M22.3988,19.6096 L20.4548,5.8806 L20.3758,5.8806 L18.4708,19.6096 L22.3988,19.6096 Z M17.6378,1.0396 L23.5498,1.0396 L28.0738,28.8156 L23.7078,28.8156 L22.9148,23.2996 L22.9148,23.3786 L17.9548,23.3786 L17.1608,28.8156 L13.1148,28.8156 L17.6378,1.0396 Z' id='Fill-33' />
        <path d='M38.3103,19.6096 L36.3663,5.8806 L36.2873,5.8806 L34.3823,19.6096 L38.3103,19.6096 Z M33.5493,1.0396 L39.4613,1.0396 L43.9853,28.8156 L39.6193,28.8156 L38.8263,23.2996 L38.8263,23.3786 L33.8663,23.3786 L33.0723,28.8156 L29.0263,28.8156 L33.5493,1.0396 Z' id='Fill-34' />
        <path d='M52.0389,12.9435 C53.6669,12.9435 54.6589,12.2295 54.6589,10.0075 L54.6589,7.8645 C54.6589,5.8805 53.9829,5.0075 52.4359,5.0075 L50.4519,5.0075 L50.4519,12.9435 L52.0389,12.9435 Z M46.0869,1.0395 L52.5549,1.0395 C57.0789,1.0395 59.0219,3.1425 59.0219,7.4285 L59.0219,9.1345 C59.0219,11.9915 58.1099,13.8165 56.1659,14.6105 L56.1659,14.6895 C58.3489,15.3635 59.0629,17.4265 59.0629,20.5615 L59.0629,25.4415 C59.0629,26.7915 59.1019,27.7835 59.5389,28.8155 L55.0949,28.8155 C54.8569,28.1015 54.6979,27.6645 54.6979,25.4025 L54.6979,20.3235 C54.6979,17.7445 53.9439,16.9115 51.9599,16.9115 L50.4519,16.9115 L50.4519,28.8155 L46.0869,28.8155 L46.0869,1.0395 Z' id='Fill-35' />
        <polygon id='Fill-36' points='62.0787 1.04 67.5547 1.04 71.7997 17.665 71.8797 17.665 71.8797 1.04 75.7677 1.04 75.7677 28.815 71.2847 28.815 66.0467 8.539 65.9677 8.539 65.9677 28.815 62.0787 28.815' />
        <polygon id='Fill-37' points='79.0215 1.04 90.9255 1.04 90.9255 5.008 83.3865 5.008 83.3865 12.348 89.3775 12.348 89.3775 16.316 83.3865 16.316 83.3865 24.847 90.9255 24.847 90.9255 28.815 79.0215 28.815' />
        <path d='M92.6714,22.2283 L92.6714,20.6413 L96.7984,20.6413 L96.7984,22.5453 C96.7984,24.5293 97.6714,25.2443 99.0604,25.2443 C100.4484,25.2443 101.3214,24.5293 101.3214,22.4663 C101.3214,20.1653 100.4484,18.6963 97.5914,16.1973 C93.9414,12.9833 92.7504,10.7213 92.7504,7.5473 C92.7504,3.1823 95.0134,0.6433 99.2584,0.6433 C103.5044,0.6433 105.6074,3.1823 105.6074,7.6263 L105.6074,8.7773 L101.4804,8.7773 L101.4804,7.3483 C101.4804,5.3643 100.6874,4.6113 99.2974,4.6113 C97.9094,4.6113 97.1154,5.3643 97.1154,7.2693 C97.1154,9.2933 98.0284,10.7613 100.8854,13.2613 C104.5364,16.4753 105.6864,18.6963 105.6864,22.1493 C105.6864,26.6723 103.3844,29.2113 99.0994,29.2113 C94.8144,29.2113 92.6714,26.6723 92.6714,22.2283' id='Fill-38' />
      </g>
    </g>
  </svg>
) : (
  <svg viewBox='0 0 176 57' version='1.1' className={styles.logo}>
    <defs>
      <polygon id='path-1' points='0.691 56.966 175.592 56.966 175.592 0.865 0.691 0.865' />
    </defs>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group-39'>
        <path d='M30.6802,2.6788 L30.6802,2.6788 L43.1552,11.7428 L55.7752,20.9348 C56.4092,21.3968 56.7452,22.3928 56.4732,23.4038 L51.7092,38.0698 L46.8662,52.9128 C46.6232,53.6578 45.7792,54.2848 44.7342,54.3398 L29.3142,54.3398 L13.7002,54.3208 C12.9172,54.3208 12.0592,53.7118 11.6852,52.7348 L11.6852,52.7338 L6.9192,38.0698 L2.1122,23.2138 C1.8712,22.4678 2.1852,21.4648 2.9992,20.8058 L15.4742,11.7428 L28.1162,2.5798 C28.7502,2.1198 29.8022,2.1088 30.6802,2.6788' id='Fill-9' fill='#FFFFFE' />
        <path d='M38.6685,22.2584 L38.6685,26.4044 C38.6685,26.6934 38.4325,26.9294 38.1435,26.9294 L20.4575,26.9294 C20.1695,26.9294 19.9335,26.6934 19.9335,26.4044 L19.9335,22.2584 C19.9335,21.9704 20.1695,21.7334 20.4575,21.7334 L38.1435,21.7334 C38.4325,21.7334 38.6685,21.9704 38.6685,22.2584 M19.2685,28.2884 L39.3325,28.2884 C39.6595,28.2884 39.9275,28.5564 39.9275,28.8844 L39.9275,33.5874 C39.9275,33.9144 39.6595,34.1824 39.3325,34.1824 L19.2685,34.1824 C18.9415,34.1824 18.6735,33.9144 18.6735,33.5874 L18.6735,28.8844 C18.6735,28.5564 18.9415,28.2884 19.2685,28.2884 M17.8075,35.5424 L40.7945,35.5424 C41.1695,35.5424 41.4765,35.8494 41.4765,36.2244 L41.4765,41.6134 C41.4765,41.9874 41.1695,42.2944 40.7945,42.2944 L17.8075,42.2944 C17.4325,42.2944 17.1245,41.9874 17.1245,41.6134 L17.1245,36.2244 C17.1245,35.8494 17.4325,35.5424 17.8075,35.5424' id='Fill-11' fill='#465676' />
        <polygon id='Fill-13' fill='#465676' points='48.3371 48.4058 40.9991 18.3958 29.9191 18.3958 28.6821 18.3958 17.6011 18.3958 10.2701 48.3808 11.3691 51.7638 19.0691 20.2668 28.6821 20.2668 29.9191 20.2668 39.5311 20.2668 47.2351 51.7808' />
        <polygon id='Fill-15' fill='#FFFFFE' points='72.7822 46.2456 74.0362 46.2456 74.0362 53.0856 76.0992 53.0856 76.0992 54.2256 72.7822 54.2256' />
        <path d='M84.6242,51.5811 L84.0652,47.6361 L84.0432,47.6361 L83.4952,51.5811 L84.6242,51.5811 Z M83.2562,46.2461 L84.9552,46.2461 L86.2542,54.2261 L85.0002,54.2261 L84.7722,52.6411 L84.7722,52.6641 L83.3472,52.6641 L83.1192,54.2261 L81.9562,54.2261 L83.2562,46.2461 Z' id='Fill-17' fill='#FFFFFE' />
        <path d='M92.3496,52.3335 L92.3496,51.8775 L93.5356,51.8775 L93.5356,52.4245 C93.5356,52.9945 93.7866,53.1995 94.1846,53.1995 C94.5846,53.1995 94.8356,52.9945 94.8356,52.4025 C94.8356,51.7405 94.5846,51.3185 93.7636,50.6005 C92.7146,49.6775 92.3726,49.0275 92.3726,48.1155 C92.3726,46.8615 93.0226,46.1325 94.2416,46.1325 C95.4626,46.1325 96.0656,46.8615 96.0656,48.1385 L96.0656,48.4695 L94.8806,48.4695 L94.8806,48.0585 C94.8806,47.4885 94.6526,47.2715 94.2536,47.2715 C93.8546,47.2715 93.6266,47.4885 93.6266,48.0355 C93.6266,48.6175 93.8886,49.0385 94.7086,49.7565 C95.7586,50.6805 96.0896,51.3185 96.0896,52.3105 C96.0896,53.6105 95.4276,54.3395 94.1966,54.3395 C92.9656,54.3395 92.3496,53.6105 92.3496,52.3335' id='Fill-19' fill='#FFFFFE' />
        <polygon id='Fill-21' fill='#FFFFFE' points='103.4496 47.3857 102.1386 47.3857 102.1386 46.2457 106.0146 46.2457 106.0146 47.3857 104.7036 47.3857 104.7036 54.2257 103.4496 54.2257' />
        <path d='M111.9504,52.3335 L111.9504,51.8775 L113.1364,51.8775 L113.1364,52.4245 C113.1364,52.9945 113.3874,53.1995 113.7854,53.1995 C114.1854,53.1995 114.4364,52.9945 114.4364,52.4025 C114.4364,51.7405 114.1854,51.3185 113.3644,50.6005 C112.3154,49.6775 111.9724,49.0275 111.9724,48.1155 C111.9724,46.8615 112.6234,46.1325 113.8424,46.1325 C115.0634,46.1325 115.6664,46.8615 115.6664,48.1385 L115.6664,48.4695 L114.4814,48.4695 L114.4814,48.0585 C114.4814,47.4885 114.2534,47.2715 113.8544,47.2715 C113.4554,47.2715 113.2274,47.4885 113.2274,48.0355 C113.2274,48.6175 113.4894,49.0385 114.3094,49.7565 C115.3594,50.6805 115.6904,51.3185 115.6904,52.3105 C115.6904,53.6105 115.0284,54.3395 113.7974,54.3395 C112.5664,54.3395 111.9504,53.6105 111.9504,52.3335' id='Fill-23' fill='#FFFFFE' />
        <mask id='mask-2' fill='white'>
          <use href='#path-1' />
        </mask>
        <g id='Clip-26' />
        <polygon id='Fill-25' fill='#FFFFFE' mask='url(#mask-2)' points='122.07 54.226 123.324 54.226 123.324 46.246 122.07 46.246' />
        <polygon id='Fill-27' fill='#FFFFFE' mask='url(#mask-2)' points='129.8761 46.2456 131.1301 46.2456 131.1301 49.6086 132.7261 46.2456 133.9811 46.2456 132.4871 49.1756 134.0031 54.2256 132.6921 54.2256 131.6321 50.6686 131.1301 51.6836 131.1301 54.2256 129.8761 54.2256' />
        <path d='M141.9449,49.6658 C142.4129,49.6658 142.6979,49.4608 142.6979,48.8218 L142.6979,48.2068 C142.6979,47.6358 142.5039,47.3858 142.0589,47.3858 L141.4899,47.3858 L141.4899,49.6658 L141.9449,49.6658 Z M140.2349,46.2458 L142.0929,46.2458 C143.3929,46.2458 143.9509,46.8498 143.9509,48.0808 L143.9509,48.5718 C143.9509,49.3918 143.6889,49.9168 143.1309,50.1448 L143.1309,50.1678 C143.7579,50.3618 143.9629,50.9538 143.9629,51.8548 L143.9629,53.2568 C143.9629,53.6438 143.9739,53.9298 144.0999,54.2258 L142.8229,54.2258 C142.7549,54.0208 142.7089,53.8948 142.7089,53.2458 L142.7089,51.7868 C142.7089,51.0448 142.4919,50.8058 141.9229,50.8058 L141.4899,50.8058 L141.4899,54.2258 L140.2349,54.2258 L140.2349,46.2458 Z' id='Fill-28' fill='#FFFFFE' mask='url(#mask-2)' />
        <polygon id='Fill-29' fill='#FFFFFE' mask='url(#mask-2)' points='150.457 54.226 151.711 54.226 151.711 46.246 150.457 46.246' />
        <polygon id='Fill-30' fill='#FFFFFE' mask='url(#mask-2)' points='158.2632 46.2456 159.8362 46.2456 161.0562 51.0226 161.0792 51.0226 161.0792 46.2456 162.1962 46.2456 162.1962 54.2256 160.9082 54.2256 159.4032 48.4006 159.3802 48.4006 159.3802 54.2256 158.2632 54.2256' />
        <path d='M168.6794,52.3335 L168.6794,48.1385 C168.6794,46.8615 169.3174,46.1325 170.5484,46.1325 C171.7804,46.1325 172.4184,46.8615 172.4184,48.1385 L172.4184,48.8225 L171.2334,48.8225 L171.2334,48.0585 C171.2334,47.4885 170.9824,47.2715 170.5824,47.2715 C170.1834,47.2715 169.9324,47.4885 169.9324,48.0585 L169.9324,52.4245 C169.9324,52.9945 170.1834,53.1995 170.5824,53.1995 C170.9824,53.1995 171.2334,52.9945 171.2334,52.4245 L171.2334,50.8625 L170.6064,50.8625 L170.6064,49.7225 L172.4184,49.7225 L172.4184,52.3335 C172.4184,53.6105 171.7804,54.3395 170.5484,54.3395 C169.3174,54.3395 168.6794,53.6105 168.6794,52.3335' id='Fill-31' fill='#FFFFFE' mask='url(#mask-2)' />
        <polygon id='Fill-32' fill='#FFFFFE' mask='url(#mask-2)' points='72.75 17.8727 68.187 17.8727 68.187 13.9047 81.678 13.9047 81.678 17.8727 77.115 17.8727 77.115 41.6797 72.75 41.6797' />
        <path d='M90.0898,32.4746 L88.1458,18.7456 L88.0668,18.7456 L86.1618,32.4746 L90.0898,32.4746 Z M85.3288,13.9046 L91.2408,13.9046 L95.7648,41.6806 L91.3988,41.6806 L90.6058,36.1646 L90.6058,36.2436 L85.6458,36.2436 L84.8518,41.6806 L80.8058,41.6806 L85.3288,13.9046 Z' id='Fill-33' fill='#FFFFFE' mask='url(#mask-2)' />
        <path d='M106.0013,32.4746 L104.0573,18.7456 L103.9783,18.7456 L102.0733,32.4746 L106.0013,32.4746 Z M101.2403,13.9046 L107.1523,13.9046 L111.6763,41.6806 L107.3103,41.6806 L106.5173,36.1646 L106.5173,36.2436 L101.5573,36.2436 L100.7633,41.6806 L96.7173,41.6806 L101.2403,13.9046 Z' id='Fill-34' fill='#FFFFFE' mask='url(#mask-2)' />
        <path d='M119.7299,25.8085 C121.3579,25.8085 122.3499,25.0945 122.3499,22.8725 L122.3499,20.7295 C122.3499,18.7455 121.6739,17.8725 120.1269,17.8725 L118.1429,17.8725 L118.1429,25.8085 L119.7299,25.8085 Z M113.7779,13.9045 L120.2459,13.9045 C124.7699,13.9045 126.7129,16.0075 126.7129,20.2935 L126.7129,21.9995 C126.7129,24.8565 125.8009,26.6815 123.8569,27.4755 L123.8569,27.5545 C126.0399,28.2285 126.7539,30.2915 126.7539,33.4265 L126.7539,38.3065 C126.7539,39.6565 126.7929,40.6485 127.2299,41.6805 L122.7859,41.6805 C122.5479,40.9665 122.3889,40.5295 122.3889,38.2675 L122.3889,33.1885 C122.3889,30.6095 121.6349,29.7765 119.6509,29.7765 L118.1429,29.7765 L118.1429,41.6805 L113.7779,41.6805 L113.7779,13.9045 Z' id='Fill-35' fill='#FFFFFE' mask='url(#mask-2)' />
        <polygon id='Fill-36' fill='#FFFFFE' mask='url(#mask-2)' points='129.7697 13.905 135.2457 13.905 139.4907 30.53 139.5707 30.53 139.5707 13.905 143.4587 13.905 143.4587 41.68 138.9757 41.68 133.7377 21.404 133.6587 21.404 133.6587 41.68 129.7697 41.68' />
        <polygon id='Fill-37' fill='#FFFFFE' mask='url(#mask-2)' points='146.7125 13.905 158.6165 13.905 158.6165 17.873 151.0775 17.873 151.0775 25.213 157.0685 25.213 157.0685 29.181 151.0775 29.181 151.0775 37.712 158.6165 37.712 158.6165 41.68 146.7125 41.68' />
        <path d='M160.3624,35.0933 L160.3624,33.5063 L164.4894,33.5063 L164.4894,35.4103 C164.4894,37.3943 165.3624,38.1093 166.7514,38.1093 C168.1394,38.1093 169.0124,37.3943 169.0124,35.3313 C169.0124,33.0303 168.1394,31.5613 165.2824,29.0623 C161.6324,25.8483 160.4414,23.5863 160.4414,20.4123 C160.4414,16.0473 162.7044,13.5083 166.9494,13.5083 C171.1954,13.5083 173.2984,16.0473 173.2984,20.4913 L173.2984,21.6423 L169.1714,21.6423 L169.1714,20.2133 C169.1714,18.2293 168.3784,17.4763 166.9884,17.4763 C165.6004,17.4763 164.8064,18.2293 164.8064,20.1343 C164.8064,22.1583 165.7194,23.6263 168.5764,26.1263 C172.2274,29.3403 173.3774,31.5613 173.3774,35.0143 C173.3774,39.5373 171.0754,42.0763 166.7904,42.0763 C162.5054,42.0763 160.3624,39.5373 160.3624,35.0933' id='Fill-38' fill='#FFFFFE' mask='url(#mask-2)' />
      </g>
    </g>
  </svg>
)

export default Logo
