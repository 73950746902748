import React from "react";
import Figure from "./Figure";

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: Figure
  },
  marks: {
    internalLink: ({ mark, children }) => {
      const { reference = {} } = mark;
      const slug = reference.slug && reference.slug.current;
      if (!slug) {
        return children;
      }
      const href = `/${slug}`;
      return <a href={href}>{children}</a>;
    },
    file: ({ mark, children }) => {
      const { asset = {} } = mark;
      const href = asset.url;
      return (
        <a href={href} target="_blank">
          {children}
        </a>
      );
    }
  }
};

export default serializers;
