import {Link} from 'gatsby'
import React from 'react'
import Menu from './menu'
import Logo from './logo'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle, pages, homePage}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Menu pages={pages} header homePage={homePage} />
      <div className={homePage ? styles.brandingWrapperHome : styles.brandingWrapper}>
        <h1 className={homePage ? styles.brandingHome : styles.branding}>
          <Link to='/'><Logo /></Link>
        </h1>
      </div>
    </div>
  </div>
)

export default Header
